.footer-div {
  background: #2b365a;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #fff;
  p {
    font-size: 12px;
  }
  h3 {
    margin-bottom: 30px;
  }
  .padd {
    padding: 10px;
  }
  .email-div {
    background: #212942;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    h5 {
      margin-bottom: 40px;
    }
    input {
      background: transparent;
      color: #fff;
      border: solid 1px #fff;
      padding: 5px 40px;
      width: 100%;
      font-family: SoraR;
    }
    button {
      background: #a0a09f;
      color: rgb(64, 60, 60);
      width: 100%;
      padding: 5px;
      margin-top: 15px;
      border: none;
      font-family: SoraR !important;
      &:hover {
        background: #fff;
      }
    }
  }
}
.credits {
  background: #181f33;
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.prefooter-writeup {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-weight: bold;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 620px) {
  }

  p {
    // font-size: 5px;
    color: #fff;
    margin-top: 30px;
  }
  button {
    background: rgba(11, 38, 65, 0.7);
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 40px;
    margin-top: 40px;
    &:hover {
      background: rgba(11, 38, 65, 0.3);
    }
  }
}
.banner3 {
  height: 500px;
  // margin-bottom: 65px;
  width: 100%;

  background: url("../../assets/images/prefooter-img.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media screen and (max-width: 620px) {
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}
.page-shadow3 {
  position: relative;
}
.page-shadow3 .after2 {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  color: #fff !important;
  display: block;
  background: rgba(2, 46, 77, 0.7) !important;

  mix-blend-mode: normal;

  display: flex;
}
