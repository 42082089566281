@media screen and (min-width: 620px) {
  .flexy {
    display: flex;
  }
}
@media screen and (max-width: 620px) {
  .flexyM {
    display: flex;
  }
}
@font-face {
  font-family: SoraM;
  src: url(./assets/fonts/sora/Sora-Medium.ttf);
}
@font-face {
  font-family: SoraB;
  src: url(./assets/fonts/sora/Sora-Bold.ttf);
}
@font-face {
  font-family: SoraEB;
  src: url(./assets/fonts/sora/Sora-ExtraBold.ttf);
}
@font-face {
  font-family: SoraR;
  src: url(./assets/fonts/sora/Sora-Regular.ttf);
}
@font-face {
  font-family: SoraSB;
  src: url(./assets/fonts/sora/Sora-SemiBold.ttf);
}
@font-face {
  font-family: SoraL;
  src: url(./assets/fonts/sora/Sora-Light.ttf);
}
@font-face {
  font-family: SerifR;
  src: url(./assets/fonts/serif/DMSerifDisplay-Regular.ttf);
}
@font-face {
  font-family: SerifI;
  src: url(./assets/fonts/serif/DMSerifDisplay-Italic.ttf);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: SerifR;
}

p,
li,
a,
small {
  font-family: SoraR;
}
button {
  font-family: SoraSB;
}
.page-background {
  @media screen and (max-width: 620px) {
    margin-top: -20px;
  }
}
p {
  font-size: 18px !important;
}
