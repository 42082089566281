$blue: #0098da;
$lightblue: #e1f2fa;
$primary: #0092dd;
@mixin get-started-button {
  color: #fff;
  border-radius: 35px;
  border: solid 1px #414244;
  padding: 8px 20px;
  font-size: 14px;
  font-family: rebondG-Semi-Bold;

  // &:hover {
  //   background: #30266b;
  //   transition: 0.5s ease;
  // }
}
@mixin login-or-signup-button {
  background: #fff !important;
  color: #414244;
  border-radius: 32px;
  padding: 8px 20px;
  font-size: 14px;
  // font-weight: bold;
  border: none;
  border: solid 2px rgb(230, 225, 225);
  font-family: rebondG-Semi-Bold;

  &:hover {
    background: rgb(231, 231, 231) !important;
    transition: 0.5s ease;
  }
}
