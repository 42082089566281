@import "../../../variables";
@import "../../../shared-styles";
.mobile-accordion {
  width: 100%;
  margin: 0 !important;

  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #2e4160;
  a {
    color: #fff;
    text-decoration: none;
  }
  .accordion-body {
    background-color: #3f66a3;
    .logo-files a {
      font-size: initial !important;
    }
  }
  &.hide {
    display: none !important;
  }
  .accordion-item {
    border: none !important;
    margin-top: -2px !important;
  }

  .accordion-button:focus {
    border-color: inherit !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .accordion-button {
    background-color: #2e4160;
    color: #fff !important;
    padding: 15px 30px;
    border: none !important;
    font-size: 18px;
  }
  .accordion-button .collapsed {
    background: rgb(0, 79, 157) !important;
    color: #fff !important;
    :hover {
      color: #fff !important;
    }
  }
  .accordion-item:first-of-type .accordion-button {
    border-radius: none !important;
    border: bone !important;
  }
  small {
    font-weight: bold !important;
  }
  .mobile-navs {
    color: $lightblue !important;
    text-decoration: none !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 35px !important;
  }
}
.home-link {
  padding: 15px 30px;
  border: none !important;
  font-size: 18px;
}
.register-btn2 {
  background: transparent;
  padding: 15px 30px;
  border: none !important;
  font-size: 18px;
  color: #fff;
}
.accordion {
  padding-top: 20px;
  padding-bottom: 20px;
}
