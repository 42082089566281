.about-banner {
  height: 600px;

  width: 100%;

  background: url("../../assets/images/banner2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 620px) {
    // margin-bottom: 100px !important;
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}
.about-us {
  p {
    margin-top: 20px;
    line-height: 30px;
    text-align: justify;
    color: rgb(71, 68, 68);
    font-size: 22px;
  }
  h3,h4,h6 {
    margin-bottom: 30px;
    color: #2e4160;
  }
  ul {
    li {
      line-height: 35px;
      list-style-type: square;
      color: rgb(71, 68, 68);
      font-size: 22px;
    }
  }
}
