@import "../../variables";
@import "../../shared-styles";

.news-component {
  margin-top: 30px;
  h4 {
    margin: 20px;
  }
}
.read-more {
  background: $primary;
  color: #fff;
  text-decoration: none;
  padding: 10px;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  font-weight: bold;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 620px) {
    top: 30%;
  }

  a {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h6 {
    margin-top: 13px;
    margin-left: 10px;
  }
  h1 {
    font-weight: bold;
    font-size: 70px !important;
    text-align: center;
    font-family: brother-bold;
  }
}
.banner {
  height: 200px;
  margin-bottom: 65px;
  width: 100%;
  background: $primary;
  background-image: url("../../assets/images/banner3.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  @media screen and (max-width: 620px) {
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}

.page-shadow .after {
  position: absolute;
  top: 4px;
  left: 0;
  width: 100% !important;
  height: 67% !important;
  color: #fff !important;
  display: block;
  background: rgba(2, 46, 77, 0.3);
  mix-blend-mode: normal;

  display: flex;
}

.zoominheader {
  -webkit-animation: zoomin 5s ease-in;
  animation: zoomin 5s ease-in;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
}

/* The innermost element*/
.zoomoutheader {
  -webkit-animation: zoomout 10s ease-in;
  animation: zoomout 10s ease-in;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  // 100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  // 100% {transform: scale(1);}
} /*End of Zoom in Keyframes */
.main-img2 {
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-link2 {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
.news-body {
  background: #f6f6f6;
  padding: 30px;
}
.share {
  li {
    color: grey;
    &:hover {
      color: $primary;
    }
  }
}
