.join-banner {
  height: 600px;

  width: 100%;

  background: url("../../assets/images/banner2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 620px) {
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}
.join-us-div {
  a {
    color: rgb(11, 94, 94);
  }
  p {
    margin-top: 30px;
  }
  h5 {
    color: grey;
    span {
      color: red;
    }
  }
  .membership-level {
    p {
      margin-top: 0px;
      font-size: 14px;
      color: grey;
    }
    input {
      margin-right: 20px;
    }
    h6 {
      margin-top: 30px;
      font-size: 18px;
    }
  }
}
.second-steps {
  input {
    width: 100%;
    border: solid 1px grey;
    padding: 10px 30px;
    font-family: SoraM;
    border-radius: 4px;
  }
  span {
    color: red;
    font-size: 14px;
    font-weight: bold;
  }
}
.steps-btn {
  border: none;
  font-family: SoraM;
  padding: 5px 15px;
  color: #fff;
  border-radius: 30px;
  background: #1d4101;
}
