@import "../../variables";
@import "../../shared-styles";

.events-component {
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 8px 8px rgb(212, 210, 210);
    transition: 0.3s ease;
  }

  .events-details {
    border-bottom: solid 3px rgba(11, 38, 65, 1);
  }
  @media screen and (max-width: 620px) {
    margin-bottom: 30px;
  }
}
