// @import "../../../variables";
// @import "../../../shared-styles";

// .backdropClassName {
//   width: 30% !important;
//   @media screen and (max-width: 620px) {
//     width: 100% !important;
//   }
// }

// .officers-profile {
//   text-align: center;
//   margin-bottom: 50px;
//   @media screen and (max-width: 620px) {
//     margin-bottom: 0px;
//   }
//   h4 {
//     text-align: center !important;
//     color: #000;
//     cursor: pointer;
//     @media screen and (max-width: 620px) {
//       font-size: 14px !important;
//     }
//     &:hover {
//       color: #283e63;
//     }
//   }
//   small {
//     color: grey;
//     cursor: pointer;
//     @media screen and (max-width: 620px) {
//       font-size: 12px !important;
//     }
//   }
//   img {

//     cursor: pointer;
//     @media screen and (max-width: 620px) {
//       width: 100%;
//       height: 200px;
//       margin-top: 40px;
//     }
//   }
//   .profile-name {
//     h6 {
//       color: #fff;
//       text-align: center;
//       background-color: rgba(24, 60, 118, 0.9);
//       padding: 10px 30px;
//       margin-top: 15px;
//       width: 100%;
//       position: relative;
//       z-index: 3;
//       font-size: 12px !important;
//       font-weight: bold;
//       @media screen and (max-width: 620px) {
//         margin-left: 0px;
//         font-size: 10px !important;
//         padding: 10px 10px;
//         width: 100%;
//       }
//     }
//   }
// }
// .offcanvas-profile-name {
//   padding: 20px;
//   height: 100px;
//   text-align: center;
//   background: rgb(245, 245, 245);
//   margin-top: 0;
//   h2 {
//     color: #283e63;
//   }
//   small {
//     color: grey;
//   }
// }
// .offcanvas-leaders-profile {
//   img {
//   }
//   .profile-portfolio {
//     h6 {
//       color: #fff;
//       background-color: rgba(24, 60, 118, 0.7);
//       padding: 10px 30px;
//       position: relative;
//       text-align: center;
//       margin-top: -35px;
//       font-size: 12px !important;
//       font-weight: bold;
//     }
//   }
// }
// .offcanvas-body {
//   padding: 0 !important;

//   img {
//     width: 100%;
//   }
// }
// .offcanvas-header .btn-close {
//   color: #000 !important;
//   position: relative;
// }
// .offcanvas-header {
//   background-color: rgba(24, 60, 118, 0.7) !important;
//   color: #fff !important;
// }
// .profile-body {
//   padding: 40px !important;
//   p {
//     line-height: 35px;
//   }
// }
// .profile-img {
//   border-radius: 50%;
// }

.modal-90w {
  width: 100% !important;
}
.off2 {
  margin-top: 180px !important;
  @media screen and (max-width: 820px) {
    margin-top: 0px !important;
  }
}
.top {
  margin-top: 350px;
  @media screen and (max-width: 820px) {
    margin-top: 30px;
  }
}
.officers-bg {
  padding-top: 120px;
  padding-bottom: 70px;
  background: rgb(233, 228, 228);
  @media screen and (max-width: 820px) {
    padding-top: 20px;
  }
}
.off1 {
  @media screen and (max-width: 820px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.president1 {
  @media screen and (max-width: 820px) {
    display: none;
  }
}
.president2 {
  @media screen and (min-width: 820px) {
    display: none;
  }
}
