.mt1 {
  margin-top: 10px;
}
.mt2 {
  margin-top: 20px;
}
.mt3 {
  margin-top: 30px;
}
.mt4 {
  margin-top: 40px;
}
.mt5 {
  margin-top: 50px;
}
.mt6 {
  margin-top: 60px;
}
.mt7 {
  margin-top: 70px;
}
.mt8 {
  margin-top: 80px;
}
.mt9 {
  margin-top: 90px;
}
.mt {
  margin-top: 140px;
}
.mtt {
  margin-top: 200px;
  @media screen and (max-width: 620px) {
    margin-top: 100px;
  }
}
.bb {
  border-bottom: solid 1px rgb(233, 229, 229);
}
.bt {
  border-top: solid 1px rgb(233, 229, 229);
}
.pd {
  padding: 80px 0;
}
.br {
  border-right: solid 1px rgb(233, 229, 229);
}
.no-m {
  @media screen and (max-width: 620px) {
    margin: 0 !important;
  }
}
.ml {
  margin-left: 10px;
}
.ml2 {
  margin-left: 20px;
}
.ml3 {
  margin-left: 30px;
}

.mb5 {
  margin-bottom: 50px;
}
.mb4 {
  margin-bottom: 40px;
}

.no-margin {
  @media screen and (max-width: 620px) {
    margin-top: 0px !important;
    text-align: center;
  }
}

.dn {
  @media screen and (max-width: 620px) {
    display: none;
  }
}
.dnW {
  @media screen and (min-width: 620px) {
    display: none;
  }
}
.dnM {
  @media screen and (max-width: 620px) {
    display: none;
  }
}
.img-resolv {
  width: 90%;
  @media screen and (max-width: 620px) {
    width: 100%;
  }
}

.app-btn {
  color: #fff;
  border-radius: 40px;
  padding: 10px 22px;
  font-size: 14px;
  font-family: SoraM;
  // background: $appcolor;
  border: none;
  text-decoration: none;
  &:hover {
    background: #ee1300;
    transition: 0.5s ease;
    color: #fff !important;
  }
  @media screen and (max-width: 620px) {
    font-size: 10px;
    padding: 8px 18px;
  }
}
@media screen and (min-width: 620px) {
  .flexy {
    display: flex;
  }
}
@media screen and (max-width: 620px) {
  .flexyM {
    display: flex;
  }
}
@media screen and (max-width: 620px) {
  .mobile-padding {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media screen and (max-width: 620px) {
  div {
    overflow: hidden;
  }
}
.padding-response {
  padding-right: 30px;
  @media screen and (max-width: 620px) {
    padding-right: 0;
  }
  p{font-size: 22px;}
}
