.membership-banner {
  height: 600px;

  width: 100%;

  background: url("../../assets/images/banner2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 620px) {
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}

.membership-body {
  svg {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  .aizuI7 {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 1);
    fill: rgb(248, 143, 143);
    fill-opacity: 0.1;
    stroke: #000;
    stroke-opacity: 1;
    stroke-width: 3px;
    filter: var(--drop-shadow, none);
    opacity: var(--opacity);
    transform: var(--flip);
  }
  a {
    color: grey;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 16px !important;
    text-align: justify !important;
  }
  .padd {
    padding: 10px;
    p {
      margin-top: 40px;
      margin-bottom: 35px;
    }
    h4 {
      color: #2e4160;
    }
  }
}

.family {
  background: #f6f6f6;
  padding-top: 50px;
  padding-bottom: 50px;
  h6 {
    text-decoration: underline;
    margin-top: 30px;
  }
  p {
    font-size: 16px !important;
    text-align: justify !important;
  }
  button {
    background: rgba(11, 38, 65, 1);
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 40px;

    &:hover {
      background: rgba(11, 38, 65, 0.3);
    }
  }
}

.peer {
  padding-bottom: 50px;
  h6 {
    text-decoration: underline;
    margin-top: 30px;
  }
  p {
    font-size: 16px !important;
    text-align: justify !important;
  }
  button {
    background: rgba(11, 38, 65, 1);
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 40px;

    &:hover {
      background: rgba(11, 38, 65, 0.3);
    }
  }
}
