@import "../../variables";
@import "../../shared-styles";

.car-div {
  margin-top: -30px;
  @media screen and (max-width: 620px) {
    margin-top: -20px;
  }
  img {
    height: 100%;
  }
  .carousel-caption {
    top: 40%;
    @media screen and (max-width: 620px) {
      top: 30%;
    }

  }
  h1 {
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: 0px 15px 14px rgba(81, 67, 21, 0.8);
    @media screen and (max-width: 620px) {
      font-size: 20px;
    }
  }
  p{text-shadow: 2px 0px 2px #777777; font-weight: bold}
  button {
    background: rgba(11, 38, 65, 0.7);
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 40px;
    margin-top: 40px;
    &:hover {
      background: rgba(11, 38, 65, 0.3);
    }
  }

  .carousel-control-next-icon {
    background-image: url("../../assets/images/next.png");
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }
  .carousel-control-prev-icon {
    background-image: url("../../assets/images/next.png");
    height: 60px;
    width: 60px;
    border-radius: 50%;
    transform: rotate(180deg);
  }
  .carousel-indicators {
    button {
      height: 20px;
      width: 20px;
      border-radius: 100%;
    }
    @media screen and (max-width: 620px) {
      display: none;
    }
  }
}
.events-div {
  background: #f3f3f3;
  padding-top: 50px;
  padding-bottom: 70px;
  text-align: center;
  .line {
    border-right: solid 1px rgb(203, 201, 201);
  }
  h3 {
    margin-bottom: 50px;
  }
  h5 {
    font-family: SoraB;
  }
  p {
    margin-bottom: 60px;
  }
  a {
    color: rgb(165, 163, 163);
    text-decoration: none;

    &:hover {
      color: #000;
    }
  }
}
.events-div {
  background: #fafafa;
  padding-top: 120px;
  padding-bottom: 120px;
}
.welcome-div {
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.youtube-video {
  background: rgb(136, 135, 135);
  padding-top: 80px;
  padding-bottom: 80px;
}
