.mission-banner {
  height: 600px;

  width: 100%;

  background: url("../../assets/images/mission.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 620px) {
    transform: translateX(0%) translateY(0%);
    object-fit: cover;
  }
}
.mission-shadow {
  position: relative;
}
.mission-shadow .after {
  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  color: #fff !important;
  display: block;
  background: rgba(2, 46, 77, 0.5) !important;
}
.mission-body {
  h3 {
    color: #2e4160;
    margin-bottom: 30px;
  }
  p {
    text-align: justify;
    font-size: 20px;
    color:#777777;
  }
  ul li{
    font-size: 20px;
    color:#777777;
  }
}
